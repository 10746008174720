.sign-main-bg {
  background: #f9f8f8;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
}

.sign-top {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: end;
  height: 20%;
  width: 100%;
}

.signLogo {
  text-align: center;
}

.sign-details {
  width: 70%;
  background-color: #ffffff;
  margin-top: 30px;
  border-radius: 10px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
}

.flex {
  display: flex;
  margin: 15px;
}

.flexs {
  display: flex;
  width: max-content;
}

.circle {
  background-color: #bf6262;
  border-radius: 25px;
  height: 25px;
  width: 25px;
  text-align: center;
  color: #ffffff;
  padding-top: 4px;
  font-size: 13px;
  margin-right: 10px;
}

.horizontal {
  width: 55px;
  height: 1px;
  /* color: #BF6262;
    background-color: #BF6262; */
  border-top: 1px solid #bf6262;
  margin: 0px;
  align-self: center;
}

.horizontals {
  width: 55px;
  height: 1px;
  /* color: #BF6262;
    background-color: #BF6262; */
  border-top: 1px solid black;
  margin: 0px;
  align-self: center;
}

.signup-head {
  color: #bab6b6;
  font-size: 14px;
  padding-top: 6px;
}

.signup-head-pink {
  color: #bf6262;
  font-size: 14px;
  padding-top: 6px;
}

.signup-inputs {
  display: flex;
  width: 70%;
  flex-wrap: wrap;
  margin: 30px;
}

.details {
  display: flex;
  flex-direction: column;
  margin: 10px;
  margin-right: 40px;
}

label {
  color: black;
  font-size: 17px;
  font-weight: 500;
  /* font-family: 'Karla'; */
  margin-bottom: 7px;
}

input {
  background: #f9f8f8;
  border-radius: 5px;
  border: 0px;
  height: 30px;
  width: 230px;
  color: #bf6262;
  font-size: medium;
}

.nextBtn {
  align-self: center;
  display: flex;
}

.circles {
  border-radius: 25px;
  height: 25px;
  width: 25px;
  text-align: center;
  color: #bf6262;
  padding-top: 4px;
  font-size: 13px;
  margin-right: 10px;
  background: rgba(191, 98, 98, 0.04);
  border: 1px dotted #bf6262;
}

.serviceItem {
  display: flex;
  background: rgba(191, 98, 98, 0.09);
  width: 25%;
  justify-content: space-around;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
  margin: 25px;
}

.correct {
  background-color: #bf6262;
  height: 20px;
  width: 20px;
  border-radius: 20px;
  text-align: center;
  padding: 4px;
}

.kkk {
  margin-top: 4px;
  margin-right: 4px;
}

.smallImage {
  height: 30px;
  width: 30px;
  margin-bottom: 5px;
  margin: 8px;
}

.name {
  font-size: 13px;
  font-weight: 600;
}

.service-bg {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.inputs {
  text-align: right;
}

.para1 {
  background: #f9f8f8;
  border-radius: 4px;
  width: 80%;
  font-size: 13px;
  padding: 15px;
}

.branding-bg {
  margin-left: 50px;
  display: flex;
  margin-top: 40px;
}

.flex1 {
  width: 50%;
}

.flex2 {
  margin-left: 35px;
}

.logo-container {
  background: rgba(217, 217, 217, 0.51);
  width: 300px;
  height: 100px;
  margin-left: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.brand {
  height: 50px;
}

.uploadBtn {
  background: rgba(191, 98, 98, 0.19);
  box-shadow: 0px 4px 4px rgba(191, 98, 98, 0.06);
  border-radius: 16px;
  border: 0px;
  margin-top: 30px;
  height: 30px;
  width: 80px;
  margin-left: 30px;
}

.logoName {
  margin-left: 40px;
}

.branding-main {
  display: flex;
  flex-direction: column;
}

.brandingNxt {
  align-self: center;
  margin-top: 40px;
  display: flex;
}

.money {
  height: 70px;
}

.subscription-bg {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
  background: rgba(186, 182, 182, 0.03);
}

.money-bg {
  align-self: center;
  text-align: center;
}

.para-bg {
  width: 70%;
  align-self: center;
  margin: 20px;
  margin-left: 130px;
  padding: 15px;
  font-size: 15px;
}

.subBtn {
  align-self: center;
  display: flex;
  margin-top: 20px;
}

.finish-bg {
  text-align: center;
}

.imageFinish {
  height: 60px;
}

.bottombtns {
  background-color: #bf6262;
  width: fit-content;
  height: 35px;
  border-radius: 20px;
  border: 0px;
  color: white;
  font-weight: bold;
  padding-left: 25px;
  padding-right: 25px;
  margin: 8px;
}

.subBtns {
  margin-top: 30px;
}

.hideBtn {
  display: none;
}

h2 {
  color: #bf6262;
}

h4:hover {
  cursor: pointer;
}

/* .check{
    margin:0px;
    border-radius:200px;
    height:20px;
    padding:0px;
    background-color: #BF6262;
} */
/* Hide the default checkbox */
.check[type="checkbox"] {
  display: none;
}

/* Style for the custom checkbox */
.custom-checkbox {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  background-color: #ffffff;
  margin-top: 3px;
}

/* Style for the tick mark */
.custom-checkbox::after {
  content: "\2713";
  font-size: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  opacity: 0;
  background-color: #bf6262;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  text-align: center;
}

/* Show the tick mark when checkbox is checked */
.check[type="checkbox"]:checked+.custom-checkbox::after {
  opacity: 1;
}

#domain {
  font-size: 16px;
}

#domain::placeholder {
  color: #000;
  /* Default color for the placeholder text */
}

#domain:focus {
  outline: none;
  /* Remove the default focus outline */
}

#domain.highlight {
  color: red;
  /* Color for the highlighted text */
}

.check-tick {
  display: flex;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
  margin: 15px;
}

.grey-col {
  color: rgba(51, 51, 51, 0.51);
  font-size: 18px;
  font-weight: 600;
}

.pink-col {
  color: #bf6262;
  font-size: 18px;
  font-weight: 600;
}

.my-path {
  fill: #bf6262;
  stroke: #bf6262;
}

.display-fit {
  margin-left: 30px;
  display: flex;
  flex-direction: column;
}

.btn {
  align-self: center;
  margin-top: 40px;
  width: fit-content;
}

/* .steps {
  display: none;
} */
.basicHide {
  display: none;
}

.horizontals {
  margin-bottom: 7px;
}

.horizontal {
  margin-bottom: 7px;
}

.buttonCrt {
  display: flex;
  flex-direction: column;
}

.stepsHidden {
  display: none;
  height: auto;
}

/* .steps-wrapper {
  height: auto;
} */
@media screen and (max-width: 820px) {
  .displayFlex {
    display: flex;
  }

  .stepsHidden {
    display: block;
    height: auto;
  }

  /* .steps {
    display: block;
  } */
  /* .steps-wrapper {
      height: auto;
    } */
  .flexs {
    display: none;
  }

  .sign-details {
    width: 100vw;
    display: flex;
  }

  .basicHide {
    display: block;
    align-self: flex-start;
    margin-left: 19px;
  }

  .signup-head-pink {
    font-size: 20px;
    font-weight: 600;
    margin-top: -1px;
  }

  .circle {
    background-color: #bf6262;
    border-radius: 30px;
    height: 30px;
    width: 30px;
    padding-top: 7px;
  }

  .sign-top {
    text-align: center;
  }

  .signLogo {
    text-align: center;
    padding-left: 0px;
    padding-top: 60px;
  }

  .hideBtn {
    display: block;
  }

  .serviceItem {
    width: 81%;
  }

  .branding-bg {
    display: flex;
    flex-direction: column;
    padding: 0px;
    margin: 0px;
  }

  .anticon svg {
    margin-bottom: 6px;
  }

  .flex1 {
    width: 100%;
    margin: 20px;
  }

  .flex2 {
    margin: 0px;
  }

  .para-bg {
    margin-left: 82px;
    width: 123%;
  }

  .sign-details {
    display: flex;
    flex-direction: column;
    width: 100vw;
    justify-content: center;
  }

  .steps {
    margin-left: 20px !important;
  }

  .service-bg {
    justify-content: flex-start;
    margin-left: -55px;
  }

  .grey-col {
    width: max-content;
  }

  .display-fit {
    margin-right: 72px;
    margin-left: 15px;
  }

  .logo-container {
    width: 212px;
  }

  .eachBagPink {
    margin: 10px;
  }

  :where(.css-dev-only-do-not-override-15rg2km).ant-steps .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #bf6262;
    border-color: #bf6262;
  }

  :where(.css-dev-only-do-not-override-15rg2km).ant-steps .ant-steps-item-process .ant-steps-item-icon {
    background-color: #bf6262;
    border-color: #bf6262;
  }

  .anticon svg {
    display: inline-block;
    color: white;
  }

  :where(.css-dev-only-do-not-override-15rg2km).ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
    background-color: #bf6262 !important;
  }

  .check-tick {
    width: max-content;
  }

  .bottombtns {
    margin-left: 3px;
  }

  .congraBtn {
    margin-left: 30px;
  }

  .circle {
    display: none;
  }

  .basicHide {
    margin-top: -16px;
  }

  .subscription-bg {
    margin-left: -47px;
  }

  .nextBtn {
    width: 67vw;
  }

  .brandingNxt {
    width: 57vw;
  }

  .subBtn {
    width: 66vw;
  }
}

@media screen and (min-width: 821px) {
  .steps {
    display: none !important;
  }
}