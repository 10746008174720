.store-page-1 {
  background-color: #ffffff;
  margin: 20px;
  height: 100%;
  padding: 15px;
  border-radius: 5px;
}

.reactIcon {
  display: flex;
  width: fit-content;
  cursor: pointer;
  align-items: center;
  margin-bottom: 20px;
}

.store-page-rows {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.paragraph-bg {
  width: 50%;
}

.sim-head {
  font-size: 14px;
  font-weight: 700;
  margin: 10px;
}

.met-head {
  color: #ff9d8c;
  font-size: 16px;
  margin: 7px;

  font-weight: 700;
}

.para1-2 {
  font-size: 13px;
  margin-left: 10px;
  color: rgba(51, 51, 51, 0.51);
}

.button-add {
  align-self: flex-end;
}

.react-icon {
  margin-right: 8px;
}

.greenButton {
  background: #40c75d;
  border-radius: 5px;
  color: white;
  border: 0px;
  text-align: center;
  width: 83px;
  font-weight: 700;
  height: 32px;
}

.hori {
  border: 1px solid rgba(0, 0, 0, 0.08);
  width: 85%;
  margin-top: 30px;
  margin-bottom: 30px;
}

.everyItem {
  width: fit-content !important;
  background: #efefef;
  border-radius: 22.5px;
  width: fit-content;
  font-size: 16px;
  padding: 6px !important;
  margin-right: 10px;
  color: #ff9d8c !important;
  background: #efefef !important;
  font-weight: 500 !important;
}

.highlited {
  background-color: #ff9d8c !important;
  color: black !important;
}

.video {
  margin: 30px;
}

.question {
  margin-left: 30px;
  margin-bottom: 30px;
}

.blueImg {
  height: 300px;
  width: 300px;
  margin: 30px;
}

.imageBlue-bg {
  text-align: center;
}

.restApi-bg {
  background: #f5f5f5;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.small-rest {
  background-color: #ffffff;
  padding: 7px;
  margin: 30px;
}

.start {
  color: #ff9d8c;
  font-size: 14px;
  font-weight: 600;
}

.post {
  color: #333333 51%;
  font-size: 14px;
  font-weight: 600;
  margin-top: -14px;
}

br {
  margin: 20px;
}

.jsonData {
  color: #3346aa;
  font-size: 16px;
}

.npm {
  align-self: flex-start;
  margin-left: 30px;
  font-weight: 700;
  color: #000;
}

.top-api {
  margin-top: 20px;
}

.eachGold {
  display: flex;
  justify-content: space-around;
  height: 70%;
}

.basicBtn {
  text-align: center;
  background: #bf6262;
  padding: 10px;
  width: 100%;
  border: 0px;
  color: white;
  font-size: 15px;
  font-weight: 600;
}

.eachPlatinum {
  background-image: url("https://res.cloudinary.com/doc438hex/image/upload/v1683952643/Vector_1_jklymq.png");
  background-size: 0cap;
  background-repeat: no-repeat;
  background-position: center;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30%;
  margin-top: 20px;
}

.main-gold-bg {
  height: 80vh;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
}

.circleDisplay {
  display: flex;
  width: 70%;
  margin-left: 20px;
  align-items: baseline;
}

.circle-div {
  background: #40c75d;
  border-radius: 10px;
  height: 10px;
  width: 10px;
  margin-top: 21px;
  margin-right: 10px;
}

.activeType {
  font-weight: 500;
}

.head-serv {
  margin-left: 20px;
  font-weight: 600;
}

.eachPlatinum2 {
  background-image: url("https://res.cloudinary.com/doc438hex/image/upload/v1683952654/Vector_2_ugzwzt.png");
  background-size: 0cap;
  background-repeat: no-repeat;
  background-position: center;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30%;
  margin-top: 20px;
}

.eachPlatinum3 {
  background-image: url("https://res.cloudinary.com/doc438hex/image/upload/v1683952664/Vector_3_iqv4mp.png");
  background-size: 0cap;
  background-repeat: no-repeat;
  background-position: center;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30%;
  margin-top: 20px;
}

.basicBtn2 {
  text-align: center;
  background: #ff9d8c;
  padding: 10px;
  width: 100%;
  border: 0px;
  color: white;
  font-size: 15px;
  font-weight: 600;
}

.basicBtn3 {
  text-align: center;
  background: #fe6d88;
  padding: 10px;
  width: 100%;
  border: 0px;
  color: white;
  font-size: 15px;
  font-weight: 600;
}

.circle-div2 {
  background: rgba(51, 51, 51, 0.51);
  border-radius: 10px;
  height: 10px;
  width: 10px;
  margin-top: 21px;
  margin-right: 10px;
}

.sales {
  color: #ff9d8c;
}

.footerLast {
  background: rgba(217, 217, 217, 0.18);
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 23%;
  width: 50%;
}

.all {
  font-size: 15px;
  font-weight: 600;
  margin-left: 5px;
}

.alls {
  font-size: 14px;
  font-weight: 600;
  margin-top: 5px;
}

@media (max-width: 820px) {
  .store-page-rows {
    flex-direction: column;
    align-items: center;
  }

  .button-add {
    align-self: center;
    margin-top: 20px;
  }

  .paragraph-bg {
    width: 100%;
  }

  .video {
    width: 100% !important;
    margin-left: 0px;
  }

  .blueImg {
    margin: 0px;
  }

  .main-gold-bg {
    height: 100%;
  }

  .eachGold {
    height: fit-content;
    flex-direction: column;
  }

  .sm-content {
    height: 290px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
  }

  .eachPlatinum {
    width: 100%;
    margin-bottom: 20px;
  }

  .eachPlatinum2 {
    width: 100%;
    margin-bottom: 20px;
  }

  .eachPlatinum3 {
    width: 100%;
    margin-bottom: 20px;
  }

  /* .store-page-1 {
    height: 302vh;
  } */
  .small-rest {
    margin: 7px;
  }

  .simulator-bg {
    width: 83vw;
  }

  .question {
    margin-left: -4px;
  }
}