.bottom-div {
  display: flex;
  margin-bottom: 60px;
}
.each-div {
  width: 30%;
  margin: 30px;
  height: 350px;
}
.image-div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #d9d9d9;
  height: 60%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.plant-div {
  width: 100%;
  padding: 10px;
  background-color: white;
  height: 30%;
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.button-div {
  text-align: center;
  width: 100%;
  background: #ff9d8c;
  height: 10%;
  border: 0px;
  font-weight: bold;
}
.community {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.31);
  font-weight: 400;
  margin-bottom: 20px;
  margin: 0px;
}
.h5-margin {
  margin: 6px;
  font-size: 18px;
  font-weight: 600;
}
.phizzle-img {
  height: 30px;
}
@media (max-width: 820px) {
  .image-div {
    width: 271%;
  }
  .plant-div {
    width: 271%;
  }
  .button-div {
    width: 271%;
  }
  .bottom-div {
    flex-direction: column;
  }
}
