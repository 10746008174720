/* @font-face {
    font-family: 'Karla';
    src: url('./karla-regular.ttf') format('truetype'),
         url('./karla-bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  } */
html {
  scroll-behavior: smooth;
  scroll-snap-type: y proximity;
  scroll-padding-top: 80px;
  /* Adjust as needed to offset any fixed headers or elements */
  scroll-padding-bottom: 80px;
  /* Adjust as needed to offset any fixed footers or elements */
  scroll-padding-left: 0;
  scroll-padding-right: 0;
  scroll-padding-inline-start: 0;
  scroll-padding-inline-end: 0;
  overscroll-behavior-y: contain;
  scroll-snap-stop: always;
  scroll-snap-points-y: repeat(100%);
}

/* Decrease the font size for the entire application */
/* body {
  font-size: 20px !important;
} */

/* Decrease the font size for specific elements */

body {
  overflow-anchor: none;
}

* {
  font-family: "Karla" !important;
}

.header-nav {
  display: flex;
  justify-content: space-between;
  width: 95vw;
}

.iotLogo {
  font-weight: 600;
  font-size: 45px;
}

.nav-names {
  width: 40%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.top-nav-names {
  font-size: 16px;
  font-weight: 600;
}

body {
  margin: 0px;
}

* {
  box-sizing: border-box;
}

.main-bg {
  padding: 3%;
  padding-top: 3%;
  background: #ffffff;
  width: 100%;
}

.hub {
  /* width: 71px;
left: 214px;
top: 79px; */

  font-family: sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  /* identical to box height, or 40px */
  color: #bf6262;
  margin-top: 16px;
  margin-left: 1%;
}

.logo-cont {
  display: flex;
  /* border: 1px solid black; */
}

img {
  height: 45%;
  margin-top: 2%;
}

.navBtn {
  text-align: center;
  color: black;
  font-size: 17px;
  font-weight: 600;
  cursor: pointer;
}

.highlight {
  color: #bf6262;
  text-align: center;
  font-size: 17px;
  font-weight: 600;
  cursor: pointer;
}

h3 {
  margin: 0px;
}

.highlights {
  border: 0px;
  border-radius: 10px;
  color: #bf6262;
  height: 30px;
  text-align: center;
}

.storeMi {
  width: 50%;
  margin-left: 40px;
  margin-top: 100px;
}

span {
  color: #bf6262;
  font-weight: 400;
}

.eco {
  color: black;
  /* font-family:"Roboto";
    font-weight: bold; */
  font-family: Arial, sans-serif;
  font-weight: 700;
}

.main-heading {
  /* font-family:"Roboto";
    font-weight: lighter; */
  font-family: Arial, sans-serif;
  font-weight: 200;
}

p {
  font-weight: 200;
}

.browse {
  background-color: #bf6262;
  border: 0px;
  border-radius: 5px;
  color: white;
  height: 35px;
  width: 140px;
  margin-top: 40px;
  text-align: center;
  font-weight: 800;
}

.powered {
  color: #bf6262;
  font-family: Arial, sans-serif;
  font-weight: 400;
  font-size: 10px;
  margin-left: 10px;
}

.logo {
  height: 30px;
  margin: 0px;
}

.storeLogo {
  margin-top: 50px;
  padding-left: 50px;
  background: rgba(250, 246, 246, 0.62);
  width: 40%;
  height: 80px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.storePink {
  background-color: #ff9d8c;
  height: max-content;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  padding-top: 17px;
}

.paraDevice {
  font-family: Arial, sans-serif;
  color: black;
  font-weight: 600;
  font-size: 22px;
}

.itemImage {
  height: 80px;
  width: 100px;
}

.eachItem {
  text-align: center;
  width: 30%;
  margin: 10px;
  margin-bottom: 30px;
}

h3 {
  text-align: center;
}

.navbar-sm {
  display: none;
}

.paraItem {
  font-family: Arial, sans-serif;
  font-size: 14px;
  color: white;
}

.itemsContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
  margin-left: 40px;
}

.expore {
  background: rgba(255, 255, 255, 0.51);
  border-radius: 14px;
  height: 35px;
  width: 78px;
  border: 0px;
  font-family: Arial, sans-serif;
  font-size: 13px;
  font-weight: 600;
}

.horizontal1 {
  width: 30%;
  background: rgba(217, 217, 217, 0.3);
  height: 25px;
  /* display: flex;
    justify-content: center;
    align-items: center; */
}

/* hr{
    border: none;
    border-top: 2px solid #ccc;
} */
h2 {
  font-family: Arial, sans-serif;
}

h3 {
  font-size: 20px;
}

.storeParaLI {
  font-family: Arial, sans-serif;
  font-size: 15px;
  color: black;
}

.paraContainer {
  margin-left: 30px;
  margin-right: 30px;
  width: 80%;
  margin-top: 20px;
}

.community {
  margin-top: 30px;
  padding-left: 50px;
  height: 30vh;
}

.storeImg {
  height: 150px;
  width: 150px;
}

.storeMiddle {
  display: flex;
  margin-left: 50px;
  height: max-content;
  margin-bottom: 10%;
}

.dropDown {
  display: none;
}

.storeIcons {
  display: flex;
  flex-direction: column;
  margin: 15px;
}

.icon {
  align-self: center;
  margin-bottom: 20px;
  text-align: center;
}

.buttonAlign {
  align-self: center;
  margin-top: 20px;
}

.cont {
  margin: 0px;
  color: #bf6262;
}

.expores {
  background-color: aliceblue;
  border-radius: 20px;
  height: 35px;
  width: 80px;
  border: 0px;
  font-family: Arial, sans-serif;
  font-size: 13px;
  font-weight: 600;
}

.image-container {
  position: relative;
}

.image-container svg:first-child {
  position: absolute;
  top: 0;
  left: 0;
}

.image-container svg:last-child {
  position: absolute;
  bottom: 0;
  right: 0;
}

.bottomCont {
  background: rgba(255, 157, 140, 0.23);
  padding-left: 30px;
  padding-right: 30px;
  /* display: flex;
    flex-direction:column;
    align-items: center; */
  padding-left: 90px;
  height: max-content;
  padding-bottom: 20px;
  padding-top: 20px;
}

.bottomPara {
  width: 70%;
  margin-left: 80px;
}

.digital {
  color: #bf6262;
  text-decoration: underline;
  font-style: italic;
  font-weight: bold;
}

.EDG {
  color: black;
  font-weight: bold;
}

.bottomButton {
  text-align: center;
  margin-top: 30px;
}

.bottombtn {
  background-color: #bf6262;
  width: 100%;
  height: max-content;
  border-radius: 10px;
  border: 0px;
  color: white;
  font-weight: bold;
  padding: 10px;
  text-align: center;
  margin: 10px;
}

.lastBottom {
  display: flex;
  flex-wrap: wrap;
  padding: 2%;
}

.lastItem {
  height: 50vh;
  background: rgba(255, 157, 140, 0.08);
  margin: 15px;
  width: 30%;
}

.blog {
  text-align: center;
  margin-top: 4%;
  background: #ffffff;
  height: max-content;
  font-weight: 600;
  font-size: 30px;
}

.footer {
  background: #bf6262;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
  height: 10vh;
  color: white;
}

.product {
  text-align: start;
}

.traingleCone {
  height: 200px;
  width: 200px;
}

.anchar {
  color: black;
  text-decoration: none;
}

a {
  text-decoration: none;
}

/* .svg1 {

    position: absolute;
    top: 0;
    left: 9px;
    z-index: 1;

}

.svg2 {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;

} */
.wrapper {
  position: relative;
  height: 80px;
  top: -140px;
  left: -83px;
}

.svg1 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.svg2 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.produc {
  text-align: start;
}

.footer-name {
  font-size: 18px;
  font-weight: 600;
}

.community-name {
  color: black;
  font-size: 32px;
  font-weight: 600;
  margin-top: 20px;
}

.text-svg {
  font-size: 25px !important;
}

#offcanvas-bg {
  width: 100%;
  text-align: start;

}

#show-bg {
  width: 60%;
  background-color: white;
  background-color: #faf6f6;
}

.button-sign {
  width: 100%;
  text-align: center;
}

.nav-home {
  text-align: start;
}

@media screen and (max-width: 820px) {
  .storeMiddle {
    display: flex;
    flex-direction: column;
    height: max-content;
  }

  .line {
    display: none;
  }

  .storeImg {
    height: 20%;
    margin-left: -64px;
  }

  .svg1 {
    height: 140%;
    margin-top: 20%;
    margin-left: 12%;
  }

  .svg2 {
    height: 140%;
    margin-top: 20%;
    margin-left: 12%;
  }

  .eachItem {
    width: 100vw;
  }

  .top-nav-names {
    font-size: 12px;
  }

  .logo-main {
    height: 25px;
  }

  .hub {
    font-size: 25px;
  }

  .nav-names {
    width: 90vw;
    display: none;
  }

  .text-svg {
    font-size: 43px !important;
  }

  /* .header-nav {
    display: flex;
    flex-direction: column;
    height: max-content;
  } */
  .highlight {
    margin-bottom: 18px;
  }

  .navbar-sm {
    display: block;
    width: fit-content;
  }

  .nav-sm {
    background-color: transparent;
  }

  .logo-cont {
    margin-top: 14px;
  }

  .horizontal1 {
    width: 58%;
    margin-left: -51px;
  }

  .lastItem {
    width: 100%;
  }

  .bottomCont {
    margin-left: -22px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .storeImgs {
    margin-top: 94px;
    margin-left: -65px;
  }

  .storeLogo {
    width: 65%;
  }

  .buildbotLogo {
    height: 39px;
  }

  .exploreButton {
    margin-left: 20px !important;
  }

  #offcanvas-bg {
    height: auto;
  }

  .storeParaLI {
    margin-left: -32px;
  }

  .cont {
    margin-left: -68px;
  }

  .expores {
    margin-left: -56px;
  }

  .iotLogo {
    margin-top: -12px;
  }

  .itemsContainer {
    margin-left: 0px;
  }
}