.news-bg {
  height: auto;
  /* display: flex;
    flex-direction: column; */
  margin-top: 20px;
  width: 100%;
  padding: 15px;
}
.slick {
  width: 90%;
  align-self: center;
  margin-left: 40px;
}
.eachVideo {
  height: 200px;
  background: #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: 0px;
}
.pause {
  width: 90px;
  height: 90px;
}
.latest {
  color: #bf6262;
}
.eachDiv {
  margin-bottom: 20px;
  width: 40%;
  margin-left: 35px;
}
.discription {
  background-color: #ffffff;
  width: 80%;
  padding: 10px;
}
.discriptions {
  background-color: #ffffff;
  width: 80%;
  padding: 10px;
}
.googleH {
  font-size: 13px;
  font-weight: 600;
}
.para11 {
  font-size: 11px;
  color: rgba(51, 51, 51, 0.51);
}
.slick-dots li button::before {
  color: #bf6262;
}
.bottomDiv {
  margin-top: 35px;
  margin-left: 30px;
}
.latests {
  color: #bf6262;
  font-size: 15px;
  margin-bottom: 20px;
}
.news-bottom-item {
  display: flex;
}
@media (max-width: 820px) {
  /* .videos {
    width: 155px !important;
  } */

  .news-bottom-item {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
  .discription {
    width: 100%;
    margin: 0px;
  }
  .eachVideo {
    width: 100%;
    margin: 0px;
  }
  .eachDiv {
    margin-left: 0px;
    width: 100%;
  }
  .discriptions {
    width: 80%;
  }
}
