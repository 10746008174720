.simCont {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02);
  border-radius: 10px;
  height: 200px;
  width: 50%;
  padding: 15px;
  margin: 20px;
  margin-top: 0px;
}

.runn {
  display: flex;
  justify-content: space-between;
  margin-left: 13px;
}

.sim-bg {
  padding: 15px;
  height: 100vh;
}

.andpharma {
  margin-left: 27px;
}

.greyColor {
  color: rgba(51, 51, 51, 0.51);
  margin-left: 10px;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 30px;
  margin-top: 20px;
}

.running {
  color: #40c75d;
  font-size: 15px;
  font-weight: 600;
}

.active {
  color: #fe6d88;
  font-size: 15px;
  font-weight: 600;
}

.redPause {
  height: 40px;
}

.runns {
  display: flex;
  justify-content: space-between;
  margin-left: 13px;
  margin-top: 45px;
  width: 25%;
  align-items: center;
  cursor: pointer;
  background: #fbfbfb;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.11);
  border-radius: 15px;
  margin-bottom: 30px;
}

.views {
  color: #bf6262;
  font-size: 20px;
}

.con {
  margin-bottom: 70px;
}

.addNew {
  background: rgba(191, 98, 98, 0.1);
  border-radius: 17px;
  border: 0px;
  color: #bf6262;
  padding: 10px;
  width: 100px;
  margin: 30px;
  margin-right: 0px;
  align-self: flex-end;
  text-align: center;
  cursor: pointer;
}

.eachBagPink {
  background-color: white;
  display: flex;
  justify-content: space-between !important;
  margin: 20px;
  height: 30%;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
}

.circleDisplay1 {
  display: flex;
  margin-left: 20px;
  margin-top: 20px;
}

.circle-div3 {
  background: rgba(51, 51, 51, 0.51);
  border-radius: 17px;
  height: 17px;
  width: 17px;
  margin-top: 21px;
  margin-right: 10px;
}

.circle-div4 {
  background: rgba(191, 98, 98, 0.44);
  border-radius: 17px;
  height: 17px;
  width: 17px;
  margin-top: 21px;
  margin-right: 10px;
}

.activeTypes {
  margin-top: 17px;
  font-size: 17px;
  font-weight: 600;
}

.activeTypes1 {
  margin-top: 17px;
  font-size: 17px;
  font-weight: 600;
  color: #bf6262;
}

.metone {
  font-size: 14px;
  font-weight: 600;
}

.met3400 {
  font-size: 14px;
  font-weight: 600;
  color: rgba(51, 51, 51, 0.51);
}

.flex-div {
  display: flex;
  flex-direction: column;
  width: 40%;
}

.finn {
  height: 100vh;
  margin-left: 10px;
}

.displayFin {
  display: flex;
}

.lastNav {
  display: flex;
  width: 100%;
}

.interface {
  background: rgba(217, 217, 217, 0.22);
  border-radius: 12px;
  padding: 10px;
  color: rgba(51, 51, 51, 0.51);
  border: 0px;
  font-weight: 700;
  margin: 20px;
  margin-top: 5px;
}

.toogleDiv {
  background-color: #ffffff;
  margin-left: 50px;
  margin-top: 30px;
  width: 40vw;
  border-radius: 10px;
}

.metones {
  font-size: 14px;
  font-weight: 600;
  color: #bf6262;
}

.reactIcon {
  margin-top: 10px;
}

@media (max-width: 820px) {
  .simCont {
    width: 100%;
    margin: 0px;
    margin-bottom: 20px;
  }

  .runns {
    width: 29%;
  }

  .flex-div {
    width: 92%;
  }

  .displayFin {
    flex-direction: column;
  }

  .toogleDiv {
    margin-left: 0px;
    width: 95vw;
  }

  .interface {
    margin: 12px;
    height: 62px;
  }

  .lastNav {
    height: 221px;
  }

  .eachBagPink {
    margin: 10px;
  }
}