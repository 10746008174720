.drawn {
  width: 22%;
  padding: 10px;
  padding-left: 30px;
  background: #ffffff;
  position: sticky;
  top: 0;
  left: 0;
  height: 80vh;
  z-index: 100;
}

.account-bg {
  display: flex;
  padding: 0px;
  height: 100%;
}

.account-bg-2 {
  height: 100%;
  background: #e6e6e6;
  flex: 1;
  overflow-y: auto;
  transform: translateZ(0);
  z-index: 0;
}

.eachStore {
  display: flex;
  background: #fbfbfb;
  border-right: 2px solid #fe6d88;
  width: 99%;
  height: 30px;
  align-items: center;
  margin: 15px;
}

.brandCont {
  margin-bottom: 20px;
  margin-left: 30px;
}

.storeIcon {
  margin: 0px;
  margin-right: 10px;
  height: 25px;
}

.eachStores {
  display: flex;
  width: 99%;
  height: 30px;
  align-items: center;
  margin: 15px;
}

.storeColor {
  color: #fe6d88;
  text-decoration: none;
  font-size: 15px;
  font-weight: 600;
}

.storeColors {
  color: black;
  text-decoration: none;
  font-size: 15px;
  font-weight: 600;
}

.accountBtn {
  background-color: transparent;
  width: max-content;
  height: max-content;
  font-size: 17px;
  border: 0px;
}

.yourAccount {
  margin-left: 30px;
  color: #bab6b6;
}

.navbarMiddile {
  margin-top: 20px;
}

.navbarMiddilesr {
  margin-left: 18px;
  margin-bottom: 80px;
  margin-top: -10px;
}

.storeLogos {
  margin-left: 20px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  height: 60px;
}

.displayFlex {
  display: flex;
  /* align-items: center; */
  margin: 0px;
}

.navbarMiddiles {
  margin-left: 7px;
  margin-bottom: 80px;
  margin-top: 2px;
}

.version {
  color: #bab6b6;
  font-size: 15px;
  font-weight: 700;
  margin-left: 60px;
}

.logout1 {
  background-color: #ffffff;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-right: 20px;
  align-items: center;
}

h5 {
  margin-left: 10px;
}

.accountAc {
  background: #ff9d8c;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  border-radius: 15.5px;
  justify-content: center;
  align-items: center;
  width: fit-content;
  border-radius: 20px;
  height: 30px;
  text-align: center;
  padding: 10px;
}

.activeItems {
  display: flex;
  justify-content: space-around;
  width: 60%;
  margin-left: 40px;
  margin-top: 20px;
}

.account-tabs-bg {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 40px;
  padding: 15px;
  /* height: 100%;
  background: #e6e6e6;
  flex: 1;
  overflow-y: auto;
  transform: translateZ(0);
  z-index: 0; */
  position: relative;
}

.all {
  height: 15px;
  margin: 0px;
  margin-right: 5px;
}

.accountAcs {
  background: #ffffff;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  border-radius: 15.5px;
  justify-content: center;
  align-items: center;
  width: fit-content;
  border-radius: 20px;
  height: 30px;
  text-align: center;
  padding: 10px;
  color: rgba(51, 51, 51, 0.51);
  font-weight: 100;
}

.whiteLine {
  border-top: 1px solid white;
  height: 2px;
  width: 100%;
}

.simulator-bg {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  border-radius: 10px;
  width: 250px;
  margin: 0px;
}

.sim {
  height: 130px;
}

h6 {
  margin: 0px;
  margin-bottom: 5px;
  font-size: 12px;
  color: #fe6d88;
}

.accountAc:hover {
  cursor: pointer;
}

.accountAcs:hover {
  cursor: pointer;
}

.accountBtn:hover {
  cursor: pointer;
}

.headSim {
  color: rgba(51, 51, 51, 0.51);
  font-size: 13px;
  margin: 10px;
  width: max-content;
  font-weight: 600;
}

.rightMark {
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 10px;
  display: flex;
  height: 23px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.right {
  margin: 0px;
  height: 15px;
}

.exploreItem {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    #ff9d8c;
  border-radius: 1px;
  border: 0px;
  padding: 7px;
  margin-top: 20px;
  color: #ffffff;
  font-weight: 600;
}

.eachSim {
  width: 30%;
  margin-top: 30px;
}

.finalCont {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-left: 20px;
}

.link-d {
  text-decoration: none;
}

.logout-icon {
  height: 20px;
  margin-bottom: 20px;
  margin-top: 15px;
}

.nav-drawn {
  display: none;
}

.logout2 {
  display: none;
}

.logos {
  height: 30px;
  margin: -12px;
}

@media (max-width: 820px) {
  .nav-drawn {
    display: block;
    width: 70vw;
  }

  .drawn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    height: 71vh;
  }

  .eachStores {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .storeIcon {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .accountBtn {
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
  }

  .storeColors {
    margin: 0;
  }

  .navbarMiddile {
    margin-top: 20px;
  }

  .yourAccount {
    margin-bottom: 10px;
  }

  .storeLogos {
    margin-top: 20px;
  }

  .logo {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }

  .hub {
    font-size: 24px;
    margin-top: 8px;
    margin-left: 2px;
  }

  .version {
    margin-top: 20px;
  }

  .drawn-kd {
    display: none;
  }

  .account-bg {
    display: flex;
    flex-direction: column;
  }

  .canvas {
    --bs-offcanvas-width: 0;
  }

  .logout1 {
    display: none;
  }

  .logout2 {
    display: block;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 10px;
  }

  .top-logout {
    display: flex;
    justify-content: space-between;
    /* position: fixed;
    z-index: 2000; */
    width: 100%;
    background-color: white;
  }

  .finalCont {
    flex-direction: column;
  }

  .activeItems {
    justify-content: start;
    width: 100%;
    margin-left: auto;
    flex-wrap: wrap;
  }

  .accountAc {
    margin-right: 5px;
  }

  .accountAcs {
    margin-right: 5px;
    margin-bottom: 8px;
  }

  .storeLogos {
    margin-top: 0px;
  }

  .logo {
    width: 114px;
    height: 33px;
    margin-right: 10px;
  }

  .navbarMiddile {
    margin-top: -4px;
  }

  .yourAccount {
    font-size: 16px;
    font-weight: 600;
    margin-top: 10px;
  }

  p {
    margin-bottom: 0rem;
  }

  .version {
    width: max-content;
    margin-top: 3px;
  }
}